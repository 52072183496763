import React from 'react';
import { useSpring, animated } from 'react-spring';
import Modal from 'react-modal';

const Logo = (props) => (
  <svg width='604' height='475' {...props} viewBox='0 0 604 475' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M517.151 223.5V129.036H540.767C555.359 129.036 566.447 133.068 574.031 141.132C581.615 149.196 585.407 160.764 585.407 175.836C585.407 183.324 584.447 190.044 582.527 195.996C580.607 201.852 577.775 206.844 574.031 210.972C570.287 215.004 565.679 218.124 560.207 220.332C554.735 222.444 548.447 223.5 541.343 223.5H517.151ZM529.103 213.708H539.903C550.943 213.708 559.199 210.396 564.671 203.772C570.239 197.148 573.023 187.836 573.023 175.836C573.023 163.836 570.239 154.668 564.671 148.332C559.199 141.996 550.943 138.828 539.903 138.828H529.103V213.708ZM472.418 353.5V328.156H431.09V320.38L470.402 261.628H483.65V318.652H496.178V328.156H483.65V353.5H472.418ZM443.618 318.652H472.418V292.012C472.418 289.516 472.466 286.588 472.562 283.228C472.754 279.772 472.946 276.796 473.138 274.3H472.562C471.41 276.508 470.21 278.668 468.962 280.78C467.714 282.892 466.418 285.052 465.074 287.26L443.618 318.652ZM518.94 353.5V346.444C525.852 339.532 531.996 333.292 537.372 327.724C542.748 322.156 547.26 317.02 550.908 312.316C554.556 307.516 557.34 303.148 559.26 299.212C561.18 295.18 562.14 291.292 562.14 287.548C562.14 282.268 560.7 277.948 557.82 274.588C554.94 271.228 550.572 269.548 544.716 269.548C540.876 269.548 537.324 270.652 534.06 272.86C530.796 274.972 527.82 277.564 525.132 280.636L518.364 273.868C522.204 269.644 526.284 266.284 530.604 263.788C534.924 261.196 540.108 259.9 546.156 259.9C554.7 259.9 561.42 262.396 566.316 267.388C571.212 272.284 573.66 278.812 573.66 286.972C573.66 291.292 572.7 295.66 570.78 300.076C568.956 304.396 566.364 308.908 563.004 313.612C559.74 318.22 555.804 323.068 551.196 328.156C546.684 333.148 541.692 338.476 536.22 344.14C538.716 343.948 541.308 343.756 543.996 343.564C546.684 343.372 549.228 343.276 551.628 343.276H578.268V353.5H518.94Z'
      fill='white'
    />
    <path
      d='M456.124 224.42C449.884 224.42 444.124 223.316 438.844 221.108C433.66 218.9 429.196 215.732 425.452 211.604C421.708 207.38 418.78 202.244 416.668 196.196C414.556 190.052 413.5 183.14 413.5 175.46C413.5 167.876 414.556 161.06 416.668 155.012C418.876 148.964 421.9 143.828 425.74 139.604C429.58 135.38 434.14 132.164 439.42 129.956C444.796 127.652 450.604 126.5 456.844 126.5C460.108 126.5 463.084 126.836 465.772 127.508C468.556 128.18 471.052 129.092 473.26 130.244C475.564 131.3 477.129 132.884 478.857 134.324C480.681 135.668 482.306 137.06 483.65 138.5L477.436 145.94C475.036 143.444 472.252 141.332 469.084 139.604C465.916 137.876 461.932 137.012 457.132 137.012C452.332 137.012 448.012 137.924 444.172 139.748C440.332 141.476 437.068 144.02 434.38 147.38C431.692 150.644 429.58 154.628 428.044 159.332C426.604 164.036 425.884 169.316 425.884 175.172C425.884 181.124 426.556 186.5 427.9 191.3C429.34 196.004 431.356 200.036 433.948 203.396C436.636 206.756 439.9 209.348 443.74 211.172C447.676 212.996 452.188 213.908 457.276 213.908C460.636 213.908 463.804 213.428 466.78 212.468C469.852 211.412 472.348 210.02 474.268 208.292V183.668H454.252V173.732H485.212V213.476C482.14 216.644 478.108 219.284 473.116 221.396C468.124 223.412 462.46 224.42 456.124 224.42Z'
      fill='white'
    />
    <path
      d='M242.116 329.456C230.212 329.456 219.268 327.152 209.284 322.544C199.492 317.936 191.044 311.408 183.94 302.96C176.836 294.32 171.268 283.952 167.236 271.856C163.396 259.568 161.476 245.84 161.476 230.672C161.476 215.504 163.396 201.968 167.236 190.064C171.268 177.968 176.836 167.792 183.94 159.536C191.044 151.088 199.492 144.656 209.284 140.24C219.268 135.824 230.212 133.616 242.116 133.616C254.02 133.616 264.868 135.92 274.66 140.528C284.644 144.944 293.188 151.376 300.292 159.824C307.588 168.08 313.156 178.256 316.996 190.352C321.028 202.256 323.044 215.696 323.044 230.672C323.044 245.84 321.028 259.568 316.996 271.856C313.156 283.952 307.588 294.32 300.292 302.96C293.188 311.408 284.644 317.936 274.66 322.544C264.868 327.152 254.02 329.456 242.116 329.456ZM242.116 308.432C250.564 308.432 258.148 306.608 264.868 302.96C271.78 299.312 277.636 294.128 282.436 287.408C287.428 280.496 291.268 272.336 293.956 262.928C296.644 253.328 297.988 242.576 297.988 230.672C297.988 218.96 296.644 208.4 293.956 198.992C291.268 189.584 287.428 181.616 282.436 175.088C277.636 168.56 271.78 163.568 264.868 160.112C258.148 156.464 250.564 154.64 242.116 154.64C233.668 154.64 225.988 156.464 219.076 160.112C212.356 163.568 206.5 168.56 201.508 175.088C196.708 181.616 192.964 189.584 190.276 198.992C187.588 208.4 186.244 218.96 186.244 230.672C186.244 242.576 187.588 253.328 190.276 262.928C192.964 272.336 196.708 280.496 201.508 287.408C206.5 294.128 212.356 299.312 219.076 302.96C225.988 306.608 233.668 308.432 242.116 308.432Z'
      fill='white'
    />
    <path d='M478 138.5V6L6 6V468.5H478V352' stroke='white' strokeWidth='11.3' />
  </svg>
);

export default Logo;

const customStyles = {
  content : {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    height         : '100vh',
    width          : '100vw',
    background     : 'rgba(0, 0, 0, 0,9)'
  }
};

export const AnimatedLogo = ({ isOpen, closeModal }) => {
  const props = useSpring({ x: 0, from: { x: 1655.5 } });
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles} contentLabel='Loading...l'>
      <animated.svg style={{ width: '80vw', height: '80vh' }} fill='none' id='animated-logo' strokeDashoffset={props.x}>
        <path d='M478 138.5V6L6 6V468.5H478V352' stroke='white' strokeWidth='11.3' />
      </animated.svg>
    </Modal>
  );
};

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import AOS from "aos"
import "aos/dist/aos.css"
import "./layout.css"
import Menu from "./menu"

class Layout extends Component {
  componentDidMount() {
    AOS.init({
      mirror: true,
    })
  }
  render() {
    const { children, ...rest } = this.props
    return (
      <StyledLayout className="container-fluid" {...rest}>
        <Menu />
        {children}
      </StyledLayout>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledLayout = styled.div`
  margin: 0px;
  padding-top: 90px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  @media (max-width: 481px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
`

export default Layout

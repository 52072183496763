export const PrimaryTheme = {
  backgroundColor: "#DD557E",
  primaryColor: "#491E49",
  text: "#f0f0f0",
  secondaryBackground: "rgba(255, 255, 255, 0.5)",
}

export const SecondaryTheme = {
  primary: "#D83768",
  secondary: "#ffffff",
  accent: "#3768d8",
}
// export const SecondaryTheme = {
//   primary   : '#DD557E',
//   secondary : '#ffffff',
//   accent    : '#3768d8'
// };
// export const SecondaryTheme = {
//   primary   : '#E1668B',
//   secondary : '#ffffff',
//   accent    : '#3768d8'
// };
// export const SecondaryTheme = {
//   primary   : '#D83768',
//   secondary : '#ffffff',
//   accent    : '#3768d8'
// };

// #7b59ff

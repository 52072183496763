import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { SecondaryTheme } from "../constants/theme"
import { FaBars, FaInstagram, FaLinkedin } from "react-icons/fa"

// Components
import Logo from "./logo"

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: "200%" },
}

const Menu = () => {
  const [open, setOpen] = useState(false)
  const ref = useRef(null)

  function OutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      document.addEventListener("touchstart", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
        document.addEventListener("touchstart", handleClickOutside)
      }
    }, [ref])
  }
  OutsideAlerter(ref)
  return (
    <StyledMenu>
      <AniLink to="/" paintDrip duration={1} hex={SecondaryTheme.primary}>
        <Logo style={{ width: 60, height: 60, marginLeft: 20 }} />
      </AniLink>
      <FaBars
        size={40}
        color={SecondaryTheme.secondary}
        style={{ marginRight: 20 }}
        className="menu-icon"
        onClick={() => setOpen(!open)}
      />
      <StyledNav animate={open ? "open" : "closed"} variants={variants}>
        <StyledNavUl>
          <li>
            <AniLink
              to="/"
              paintDrip
              duration={1}
              hex={SecondaryTheme.primary}
              activeStyle={{
                borderBottomColor: SecondaryTheme.secondary,
                borderBottomWidth: 5,
                borderBottomStyle: "solid",
              }}
            >
              Home
            </AniLink>
          </li>
          <li>
            <AniLink
              to="/about-us"
              paintDrip
              duration={1}
              hex={SecondaryTheme.primary}
              activeStyle={{
                borderBottomColor: SecondaryTheme.secondary,
                borderBottomWidth: 5,
                borderBottomStyle: "solid",
                lineHeight: 1.5,
              }}
            >
              About Us
            </AniLink>
          </li>
          <li>
            <AniLink
              to="/blog"
              paintDrip
              duration={1}
              hex={SecondaryTheme.secondary}
              activeStyle={{
                borderBottomColor: SecondaryTheme.secondary,
                borderBottomWidth: 5,
                borderBottomStyle: "solid",
              }}
            >
              Blog
            </AniLink>
          </li>
          <li
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <a
              alt="instagram"
              href="https://www.linkedin.com/company/ogd42/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={40} />
            </a>
            <a
              alt="instagram"
              href="https://www.instagram.com/ogd42business/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={40} />
            </a>
          </li>
        </StyledNavUl>
      </StyledNav>
      <ul>
        <li>
          <AniLink
            to="/"
            paintDrip
            duration={1}
            hex={SecondaryTheme.secondary}
            activeStyle={{
              borderColor: SecondaryTheme.secondary,
              borderStyle: "solid",
            }}
          >
            Home
          </AniLink>
        </li>
        <li>
          <AniLink
            to="/about-us"
            paintDrip
            duration={1}
            hex={SecondaryTheme.secondary}
            activeStyle={{
              borderColor: SecondaryTheme.secondary,
              borderStyle: "solid",
            }}
          >
            About Us
          </AniLink>
        </li>
        <li>
          <AniLink
            to="/blog"
            paintDrip
            duration={1}
            hex={SecondaryTheme.secondary}
            activeStyle={{
              borderColor: SecondaryTheme.secondary,
              borderStyle: "solid",
            }}
          >
            Blog
          </AniLink>
        </li>
      </ul>
    </StyledMenu>
  )
}

const StyledMenu = styled.nav`
  position: fixed;
  z-index: 9999999999;
  top: 0px;
  background-color: ${SecondaryTheme.primary};
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  .menu-icon {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  & > ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    color: ${SecondaryTheme.secondary};
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.2rem;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    height: 70px;
    @media (max-width: 768px) {
      display: none;
    }
    li {
      text-transform: uppercase;
      padding: 10px;
      a {
        text-decoration: none;
        color: ${SecondaryTheme.secondary};
        padding: 10px;
      }
    }
  }
`

const StyledNav = styled(motion.nav)`
  display: flex;
  position: absolute;
  min-width: 0px;
  flex-basis: 400;
  flex-direction: column;
  background-color: ${SecondaryTheme.primary};
  padding: 2rem;
  transform: translateX(200%);
  right: 0px;
  top: 150px;
  width: 220px;
  box-shadow: 15px 15px 15px 5px rgba(0, 0, 0, 0.35);
  z-index: 99999;
  border-radius: 10px;
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
`

const StyledNavUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  li {
    height: 450xp;
    margin: 10px;
    margin-top: 20px;
    width: 40%;
    text-align: center;
    a {
      color: ${SecondaryTheme.secondary};
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 1.4rem;
      }
    }
  }
`

export default Menu
